export default {
  address: {
    contactNumberLength: 8,
    contactNumberMinLength: 0,
    emailRequired: true,
    hidePin: true,
    hideCountry: true,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  app: 'mtr',
  appName: 'MTR',
  allowInsufficientPoint: true,
  allowPayment: true,
  contactInfo: {
    en: `<div class="slide-contact-header">Please contact the MMB Contact Centre should you require any assistance:</div>
    <div class="slide-contact-data"><label>Contact No</label>: +852 5803 3606</div>
    <div class="slide-contact-data"><label>PIN Code</label>: 2540</div>
    <div class="slide-contact-data"><label>E-mail</label>: MTRFlexibleBenefits@MercerMarshBenefits.com</div>
    <div class="slide-contact-data"><label>Operating Hours</label>: 9:00 a.m. to 5:30 p.m.</div>
    <div class="slide-contact-data"><label>Mondays to Fridays (excluding Public Holidays of Hong Kong)</label></div>`,
    zn_HK: `<div class="slide-contact-header">如需要任何協助，請與MMB聯絡中心聯絡。</div>
    <div class="slide-contact-data"><label>聯絡電話:</label> +852 5803 3606</div>
    <div class="slide-contact-data"><label>客戶代碼:</label> 2540</div>
    <div class="slide-contact-data"><label>電郵:</label> MTRFlexibleBenefits@MercerMarshBenefits.com</div>
    <div class="slide-contact-data"><label>辦工時間:</label> 9:00 a.m. to 5:30 p.m.</div>
    <div class="slide-contact-data"><label>週一至週五 (香港公眾假期除外)</label></div>`,
  },
  defaultCurrency: 'HKD',
  defaultLanguage: 'en',
  defaultUserIcon: 'assets/img/user.png',
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiringPointQueryString: '&for=2',
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  freshchat: {
    isShow: false,
    pages: ''
  },
  footer: {
    dynamicLogo: false,
    userGuide: [{
      lang: 'en',
      file: 'https://cerrapoints.com/media/attachment/1706525378.pdf',
    }, {
      lang: 'zh-hant',
      file: 'https://cerrapoints.com/media/attachment/1706525421.pdf',
    }],
  },
  header: {
    dynamicBg: true,
    dynamicLogo: false,
    firstLogo: true,
    secondLogo: 'assets/img/org/logo-footer.svg',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: false,
  },
  headerLogoMaxHeight: '30px',
  hideSFStoreSFLocker: false,
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  languageAvailable: ['en', 'zh-hant'],
  languageLabel: {
    en: 'English',
    'zh-hant': '繁體中文',
  },
  links: {
    sfLocker:
      'https://htm.sf-express.com/hk/en/dynamic_function/S.F.Network/SF_Locker/',
    sfStore:
      'https://htm.sf-express.com/hk/en/dynamic_function/S.F.Network/SF_store_address/',
  },
  loginReadMore: true,
  loginTermsCondition: false,
  modules: {
    home: true,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    settings: true,
    pointsHistory: false,
    cart: true,
    leaderboard: false,
  },
  panel: {
    link: ['/profile/edit'],
    support: false,
  },
  pointDetailHeader: 'common.pointsRecord',
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
  },
  settings: {
    hideMyAppreciationMessagesToggle: false,
    makeMyBirthdayPublicToggle: false,
    makeMyAnniversaryPublicToggle: false,
    makeMyProfilePublicToggle: false,
  },
  showServiceBanner: true,
  voucherStatus: [0, 2, 3, 5, 8, 9],
  zhLang: 'zh-hant',
  receipt: {
    showBenefitFor: false,
    showRemarks: false,
    showUserGuide: true,
  },
  showExpiringPoints: false,
  showYearlyExpiringPoints: false,
};
